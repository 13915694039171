/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useRef } from "react";

export const useOuterClick = (
  ref: React.RefObject<HTMLDivElement>,
  callback?: (event?: any) => unknown
) => {
  useEffect(() => {
    const handleClickOutside = (event: any) => {
      if (ref.current && !ref.current.contains(event?.target)) {
        callback?.(event);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [ref]);
};

export const OuterClick = ({
  press,
  children,
}: {
  press?: () => unknown;
  children: JSX.Element | JSX.Element[];
}) => {
  const wrapper = useRef<HTMLDivElement>(null);
  useOuterClick(wrapper, press);

  return <div ref={wrapper}>{children}</div>;
};

export default OuterClick;
