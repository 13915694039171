import { useService } from "@with-nx/hooks-n-helpers";
import { SortField } from "@with-nx/simple-ui/organisms";
import { useEffect, useState } from "react";
import { mutate } from "swr";

export type UseDropShow = {
  id: string;
  name: string;
  slug: string;
  coverDrop?: {
    id: string;
    name: string;
    attachments: {
      name: string;
      blob: {
        signedUrl: string;
      };
    }[];
  };
};

export const useDropShows = ({
  page = 1,
  per = 12,
  region = 1,
  search = "",
  sortBy,
}: {
  page?: number;
  per?: number;
  region?: number;
  search?: string;
  sortBy?: SortField;
}) => {
  const [loading, _loading] = useState<boolean>(false);
  const [data, _data] = useState<UseDropShow[]>([]);
  const [pages, _pages] = useState<number>(0);

  useEffect(() => {
    const makeRequestToMicroservice = useService("microservice", {
      cache: 1_800_000,
    });

    let current = true;

    (async () => {
      _loading(true);
      const params = new Map();

      params.set("pagination[page]", page.toString());
      params.set("pagination[pageSize]", per.toString());
      params.set("includes[coverDrop]", "true");
      params.set("includes[coverDrop_attachments]", "true");

      if (sortBy) {
        params.set(`pagination[orderBy][${sortBy.value}]`, sortBy.order);
      }

      if (search?.length > 0) {
        params.set("filter[searchText]", search?.trim());
      }

      const parameters = (() => {
        const t: {
          [key: string]: string;
        } = {};
        params.forEach((value, key) => {
          t[key] = value;
        });

        return t;
      })();

      const request = await makeRequestToMicroservice(
        "GET",
        `/ecommerce/products/search/${region}/digital-drops/show-brands`,
        parameters
      );

      if (request?.["result"] && current) {
        _data(request?.["result"]);
        _pages(Math.ceil(request?.["total"] / request?.["pageSize"]));
        _loading(false);
      }
    })();

    return () => {
      current = false;
    };
  }, [page, region, search, sortBy?.value, sortBy?.order]);

  return {
    loading,
    data,
    pages,
    mutate,
  };
};

export default useDropShows;
