import latestDrops from "../../../apps/catalog/data/latestDrops.json";

export class LatestDigitalDropsStaticData {
  static isEmpty() {
    return latestDrops.length === 0;
  }

  static getAll() {
    return latestDrops.filter((drop) => !!drop?.attachments?.length);
  }
}
