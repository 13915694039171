import { useEffect, useRef } from "react";

interface UseInfiniteScrollProps {
  onLoadMore: VoidFunction;
  threshold?: number;
}

export const useInfiniteScroll = ({
  onLoadMore,
  threshold = 1.0,
}: UseInfiniteScrollProps) => {
  const observer = useRef<any>();
  const lastElementRef = useRef();

  useEffect(() => {
    observer.current = new IntersectionObserver(
      (entries) => {
        if (entries[0].isIntersecting) {
          onLoadMore();
        }
      },
      {
        threshold,
      }
    );

    if (lastElementRef.current) {
      observer.current.observe(lastElementRef.current);
    }

    return () => {
      if (observer.current) observer.current.disconnect();
    };
  }, [onLoadMore]);

  return lastElementRef;
};
