import { useMobile } from "@with-nx/hooks-n-helpers";
import { Row } from "antd";
import { Box } from "simple-effing-primitive-layout";

interface ReusableGradientBackgroundProps {
  image?: string;
  children?: JSX.Element | JSX.Element[];
  minHeight?: number;
}

export const ReusableGradientBackground = ({
  image = "/assets/hero/blue-gradient-hero.png",
  children,
  minHeight = 600,
}: ReusableGradientBackgroundProps) => {
  const mobile = useMobile();
  return (
    <Row align="middle" justify="center">
      <Box
        parse="d:flex a:center"
        style={{
          backgroundImage: `url(${image})`,
          backgroundSize: "contain",
          backgroundPosition: "center bottom",
          backgroundRepeat: "no-repeat",
          minHeight: mobile ? undefined : minHeight,
        }}
      >
        <Box css="container">{children}</Box>
      </Box>
    </Row>
  );
};

export default ReusableGradientBackground;
