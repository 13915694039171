import { useCustomMobile } from "@with-nx/hooks-n-helpers";
import { DesignedIcon, Rule } from "@with-nx/simple-ui/atoms";
import { FC } from "react";

import { Box } from "simple-effing-primitive-layout";

interface SortProps {
  options: {
    title: string;
    value: string;
  }[];
  order?: SortOrder;
  selected: string;
  onApplySort: (option: string, order: SortOrder) => void;
}

export type SortOrder = "ASC" | "DESC" | "";
export interface SortField {
  value: string;
  order: SortOrder;
}

export const Sort: FC<SortProps> = ({
  options,
  selected,
  order,
  onApplySort,
}) => {
  const mobile = useCustomMobile();
  const separator = (
    <Box
      parse="d:block w:1 ml:15 mr:15 h:20"
      border="1px solid var(--grey-80)"
    />
  );

  return (
    <Box
      parse={`d:flex a:center c:var(--blue-100) br:10 pa:25 pt:15 pb:15 w:fit-content ${
        mobile ? "mb:10 w:100%" : ""
      }`}
    >
      {options.map(({ title, value }, index) => {
        const last = index === options.length - 1;

        return (
          <Box
            parse="d:flex a:center"
            key={`sort-option-${value}`}
            style={{
              cursor: "pointer",
            }}
            press={() => onApplySort(value, order === "ASC" ? "DESC" : "ASC")}
          >
            {value === selected && (
              <Box parse="d:flex a:center" right={8}>
                <DesignedIcon
                  name={order === "ASC" ? "up_caret" : "down_caret"}
                  size={mobile ? 14 : 24}
                />
              </Box>
            )}

            <Rule
              parse={`!${mobile ? "_bs" : "_bxl"} c:var(--grey-20)`}
              weight={700}
            >
              {title}
            </Rule>
            {!last && separator}
          </Box>
        );
      })}
    </Box>
  );
};
