/* eslint-disable @typescript-eslint/no-explicit-any */
import { useMobile } from "@with-nx/hooks-n-helpers";
import { useRouter } from "next/router";
import React, { ReactNode } from "react";
import { Box } from "simple-effing-primitive-layout";

import Colors from "../../../../helpers/src/lib/Colors";
import DesignedIcon from "../designed-icon/designed-icon";
import Rule from "../rule/rule";
import { DesignedCardCart, DesignedCardPrice } from "./designed-card";

interface OutsideCardProps {
  cart?: DesignedCardCart;
  title?: string;
  subtitle?: ReactNode;
  price?: DesignedCardPrice;
  children?: ReactNode;
  tags?: string[];
  properties?: {
    [key: string]: unknown;
  };
  press?: () => unknown;
  mode?: "default" | "redirect";
}

export const OutsideCard: React.FC<OutsideCardProps> = ({
  title,
  cart,
  subtitle,
  price,
  press,
  properties,
  children,
  mode = "default",
}) => {
  if (!title && !subtitle && !price && !cart && !children) {
    return null;
  }

  const router = useRouter();
  const mobile = useMobile();

  const authenticate = (cart: DesignedCardCart) => {
    if (!window) {
      return;
    }

    const _url = new URL(window?.location?.origin + "/auth");
    _url.search = new URLSearchParams({
      redirect: window.location.pathname,
      action: "Continue",
      form: "register",
      ...cart.properties,
    }).toString();
    router.push(_url.toString());
  };
  return (
    <Box
      parse="d:flex a:flex-start fd:row p:relative j:space-between"
      press={press}
      style={{
        padding:
          mode === "redirect"
            ? 12
            : (properties?.["style"] as any)?.padding || 0,
        backgroundImage:
          mode === "redirect"
            ? "linear-gradient(0deg, rgba(30, 36, 45, 0.5) 0%, rgba(30, 36, 45, 0) 100%)"
            : "none",
        borderBottomLeftRadius: mode === "redirect" ? 5 : 0,
        borderBottomRightRadius: mode === "redirect" ? 5 : 0,
      }}
      {...properties}
      top={mode === "redirect" ? 0 : 12}
    >
      <Box
        width={
          mode === "redirect"
            ? undefined
            : !price
            ? "100%"
            : "calc(100% - 48px)"
        }
      >
        {title && (
          <Rule
            color={Colors.font1}
            rule="lm"
            display="block"
            style={{
              ...(cart
                ? {
                    width: "100%",
                    overflowX: "hidden",
                    whiteSpace: "nowrap",
                    textOverflow: "ellipsis",
                  }
                : {}),
            }}
          >
            {title}
          </Rule>
        )}

        {subtitle && (
          <Rule color={Colors.font4} rule="lt" top={5} display="block">
            {subtitle}
          </Rule>
        )}

        {children}
      </Box>

      <Box parse="d:inline-flex a:flex-start j:space-between">
        {price && (
          <Box parse="d:inline-flex a:flex-end fd:column">
            {price.undiscounted && (
              <Rule
                color={Colors.font4}
                rule="lt"
                display="block"
                style={{ textDecoration: "line-through" }}
              >
                {price.undiscounted}
              </Rule>
            )}
            {price.price && (
              <Rule
                color={Colors.font1}
                rule="ls"
                display="block"
                line={mobile ? 18 : 20}
              >
                {price.price}
              </Rule>
            )}
          </Box>
        )}

        {cart && (
          <Box
            parse="w:32 h:32 br:5 d:inline-flex a:center j:center"
            color={cart.added ? Colors.positive : Colors.accent}
            press={() => {
              if (cart?.authenticated === false) {
                authenticate(cart);
              } else {
                if (cart?.press) {
                  cart?.press();
                }
              }
            }}
            {...cart?.properties}
          >
            <DesignedIcon
              name="cart/regular"
              size={24}
              color={cart.added ? Colors.font1 : Colors.font4}
            />
          </Box>
        )}

        {mode === "redirect" ? (
          <DesignedIcon size={24} color={Colors.primary} name="right/bold" />
        ) : undefined}
      </Box>
    </Box>
  );
};

export default OutsideCard;
