import { Fuzzy } from "libs/hooks-n-helpers/src/Fuzzy";
import shows from "../../../apps/catalog/data/shows.json";

export class ShowDataStatic {
  static isShowsEmpty() {
    return shows.length === 0;
  }

  static getAllShows() {
    return shows;
  }

  static getShowBySlug = (slug: string) => {
    return shows?.find((show) => show.slug === slug);
  };

  static search(term?: string) {
    const similarities: any[] = [];

    if (!term || term?.length === 0) {
      return similarities;
    }

    const shows = this.getAllShows();

    shows.forEach((show) => {
      if (show.title === term || show.title.includes(term)) {
        return similarities.push({ ...show, similarity: 1 });
      }

      if (Fuzzy.simplify(show.title)?.startsWith(Fuzzy.simplify(term))) {
        return similarities.push({ ...show, similarity: 0.9 });
      }

      if (
        Fuzzy.slugify(show.title) === Fuzzy.slugify(term) ||
        Fuzzy.slugify(show.title).includes(Fuzzy.slugify(term))
      ) {
        return similarities.push({ ...show, similarity: 0.8 });
      }

      const similarity = Fuzzy.similarity(term, show.title);

      if (similarity > 0.4) {
        return similarities.push({ ...show, similarity });
      }

      return false;
    });

    return similarities
      .sort((a, b) => (b?.similarity || 0) - (a?.similarity || 0))
      .filter((show) => !!show.title);
  }

  static getFeaturedEventsTags() {
    return [
      {
        id: "100",
        name: "Banquets",
        category: "Style",
        createdAt: "2024-07-12T15:21:40.212411+00:00",
        updatedAt: "2024-07-12T15:21:40.212411+00:00",
        coverDrop: {
          name: "1920s Night Club with Stage",
          type: "DigitalDrops::Scene",
          cueDescription: null,
          cueLine: null,
          cuePageNumber: null,
          public: true,
          featuredRank: null,
          userStarsCount: 0,
          createdAt: "2024-05-28T17:47:22.275Z",
          updatedAt: "2024-06-25T15:21:28.919Z",
          id: "9079",
          attachments: [
            {
              name: "preview_video",
              recordType: "Scene",
              recordId: "9079",
              createdAt: "2024-05-28T17:54:41.646Z",
              blob: {
                key: "jzdtrowym7urav85c74ibgm9da2j",
                filename: "06 Night Club Drop 30S-preview.mp4",
                contentType: "video/mp4",
                metadata: {
                  width: 320,
                  height: 180,
                  duration: 5.005,
                  audio: false,
                  video: true,
                  video_codec: "H.264 / AVC / MPEG-4 AVC / MPEG-4 part 10",
                  container_format: "QuickTime / MOV",
                  analyzed: true,
                  identified: true,
                },
                byteSize: "13585",
                checksum: "ZXob9Uv/GcP0baXxeTADgQ==",
                serviceName: "s3",
                createdAt: "2024-05-28T17:54:41.580Z",
                id: "292737",
                signedUrl:
                  "https://bmd-accounts-production.s3.us-west-2.amazonaws.com/jzdtrowym7urav85c74ibgm9da2j?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Content-Sha256=UNSIGNED-PAYLOAD&X-Amz-Credential=AKIA5TOQJU3JOA543BIW%2F20240904%2Fus-west-2%2Fs3%2Faws4_request&X-Amz-Date=20240904T143234Z&X-Amz-Expires=604800&X-Amz-Signature=9890691863ecd1a2ca5f9244785935ad04bae3f06dbb4d6f49efb414a96a0598&X-Amz-SignedHeaders=host&x-id=GetObject",
              },
              id: "330276",
            },
            {
              name: "preview_still",
              recordType: "Scene",
              recordId: "9079",
              createdAt: "2024-05-28T17:54:44.336Z",
              blob: {
                key: "hygfwdyzw1s227nyajlnuvvlr0tf",
                filename: "06 Night Club Drop 30S-preview.webp",
                contentType: "image/webp",
                metadata: {
                  width: 320,
                  height: 180,
                  audio: false,
                  video: true,
                  video_codec: "WebP",
                  container_format: "piped webp sequence",
                  analyzed: true,
                  identified: true,
                },
                byteSize: "7958",
                checksum: "bWtCiQ5b4hAqLvcLK8OZAg==",
                serviceName: "s3",
                createdAt: "2024-05-28T17:54:44.274Z",
                id: "292742",
                signedUrl:
                  "https://bmd-accounts-production.s3.us-west-2.amazonaws.com/hygfwdyzw1s227nyajlnuvvlr0tf?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Content-Sha256=UNSIGNED-PAYLOAD&X-Amz-Credential=AKIA5TOQJU3JOA543BIW%2F20240904%2Fus-west-2%2Fs3%2Faws4_request&X-Amz-Date=20240904T143234Z&X-Amz-Expires=604800&X-Amz-Signature=e59e1f1578a80b41c2833635be0e24ae5cf976815ae1e27366d5f81f7ca540f7&X-Amz-SignedHeaders=host&x-id=GetObject",
              },
              id: "330281",
            },
          ],
        },
      },
      {
        id: "104",
        name: "Choral",
        category: "Style",
        createdAt: "2024-07-12T15:23:29.242768+00:00",
        updatedAt: "2024-07-12T15:23:29.242768+00:00",
        coverDrop: {
          name: "1950s Abstract Retro Shapes Background",
          type: "DigitalDrops::Scene",
          cueDescription: null,
          cueLine: null,
          cuePageNumber: null,
          public: true,
          featuredRank: null,
          userStarsCount: 0,
          createdAt: "2024-05-07T15:20:31.920Z",
          updatedAt: "2024-05-21T13:11:27.915Z",
          id: "9025",
          attachments: [
            {
              name: "preview_still",
              recordType: "Scene",
              recordId: "9025",
              createdAt: "2024-05-07T15:21:19.007Z",
              blob: {
                key: "2fuogse2jvhkztqfnbmucjziifqg",
                filename: "1950s Retro Shapes Background-preview.webp",
                contentType: "image/webp",
                metadata: {
                  width: 320,
                  height: 180,
                  audio: false,
                  video: true,
                  video_codec: "WebP",
                  container_format: "piped webp sequence",
                  analyzed: true,
                  identified: true,
                },
                byteSize: "5476",
                checksum: "aqN0Dr6PnmjBFNDoQo26eQ==",
                serviceName: "s3",
                createdAt: "2024-05-07T15:21:18.941Z",
                id: "291293",
                signedUrl:
                  "https://bmd-accounts-production.s3.us-west-2.amazonaws.com/2fuogse2jvhkztqfnbmucjziifqg?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Content-Sha256=UNSIGNED-PAYLOAD&X-Amz-Credential=AKIA5TOQJU3JOA543BIW%2F20240904%2Fus-west-2%2Fs3%2Faws4_request&X-Amz-Date=20240904T143234Z&X-Amz-Expires=604800&X-Amz-Signature=76341917d2257bf8b92b36f6b030c796d897c58a30d047bd7d82612bb4235de4&X-Amz-SignedHeaders=host&x-id=GetObject",
              },
              id: "328834",
            },
            {
              name: "preview_video",
              recordType: "Scene",
              recordId: "9025",
              createdAt: "2024-05-07T15:21:25.618Z",
              blob: {
                key: "apoez5j3ps1j4oy3x3vn3knvk4ir",
                filename: "1950s Retro Shapes Background-preview.mp4",
                contentType: "video/mp4",
                metadata: {
                  width: 320,
                  height: 180,
                  duration: 5.005,
                  audio: false,
                  video: true,
                  video_codec: "H.264 / AVC / MPEG-4 AVC / MPEG-4 part 10",
                  container_format: "QuickTime / MOV",
                  analyzed: true,
                  identified: true,
                },
                byteSize: "15190",
                checksum: "MHt2yA8Ykhr85b5mI/lxAg==",
                serviceName: "s3",
                createdAt: "2024-05-07T15:21:25.550Z",
                id: "291297",
                signedUrl:
                  "https://bmd-accounts-production.s3.us-west-2.amazonaws.com/apoez5j3ps1j4oy3x3vn3knvk4ir?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Content-Sha256=UNSIGNED-PAYLOAD&X-Amz-Credential=AKIA5TOQJU3JOA543BIW%2F20240904%2Fus-west-2%2Fs3%2Faws4_request&X-Amz-Date=20240904T143234Z&X-Amz-Expires=604800&X-Amz-Signature=f3fa4f3019f57a9a21a8354b89a38d6584ac08ef7f3dc5ade90250b8774ce0d8&X-Amz-SignedHeaders=host&x-id=GetObject",
              },
              id: "328838",
            },
          ],
        },
      },
      {
        id: "97",
        name: "Conferences",
        category: "Style",
        createdAt: "2024-07-12T15:19:07.520714+00:00",
        updatedAt: "2024-07-12T15:19:07.520714+00:00",
        coverDrop: {
          name: "1950s Abstract Retro Shapes Background",
          type: "DigitalDrops::Scene",
          cueDescription: null,
          cueLine: null,
          cuePageNumber: null,
          public: true,
          featuredRank: null,
          userStarsCount: 0,
          createdAt: "2024-05-07T15:20:31.920Z",
          updatedAt: "2024-05-21T13:11:27.915Z",
          id: "9025",
          attachments: [
            {
              name: "preview_still",
              recordType: "Scene",
              recordId: "9025",
              createdAt: "2024-05-07T15:21:19.007Z",
              blob: {
                key: "2fuogse2jvhkztqfnbmucjziifqg",
                filename: "1950s Retro Shapes Background-preview.webp",
                contentType: "image/webp",
                metadata: {
                  width: 320,
                  height: 180,
                  audio: false,
                  video: true,
                  video_codec: "WebP",
                  container_format: "piped webp sequence",
                  analyzed: true,
                  identified: true,
                },
                byteSize: "5476",
                checksum: "aqN0Dr6PnmjBFNDoQo26eQ==",
                serviceName: "s3",
                createdAt: "2024-05-07T15:21:18.941Z",
                id: "291293",
                signedUrl:
                  "https://bmd-accounts-production.s3.us-west-2.amazonaws.com/2fuogse2jvhkztqfnbmucjziifqg?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Content-Sha256=UNSIGNED-PAYLOAD&X-Amz-Credential=AKIA5TOQJU3JOA543BIW%2F20240904%2Fus-west-2%2Fs3%2Faws4_request&X-Amz-Date=20240904T143234Z&X-Amz-Expires=604800&X-Amz-Signature=76341917d2257bf8b92b36f6b030c796d897c58a30d047bd7d82612bb4235de4&X-Amz-SignedHeaders=host&x-id=GetObject",
              },
              id: "328834",
            },
            {
              name: "preview_video",
              recordType: "Scene",
              recordId: "9025",
              createdAt: "2024-05-07T15:21:25.618Z",
              blob: {
                key: "apoez5j3ps1j4oy3x3vn3knvk4ir",
                filename: "1950s Retro Shapes Background-preview.mp4",
                contentType: "video/mp4",
                metadata: {
                  width: 320,
                  height: 180,
                  duration: 5.005,
                  audio: false,
                  video: true,
                  video_codec: "H.264 / AVC / MPEG-4 AVC / MPEG-4 part 10",
                  container_format: "QuickTime / MOV",
                  analyzed: true,
                  identified: true,
                },
                byteSize: "15190",
                checksum: "MHt2yA8Ykhr85b5mI/lxAg==",
                serviceName: "s3",
                createdAt: "2024-05-07T15:21:25.550Z",
                id: "291297",
                signedUrl:
                  "https://bmd-accounts-production.s3.us-west-2.amazonaws.com/apoez5j3ps1j4oy3x3vn3knvk4ir?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Content-Sha256=UNSIGNED-PAYLOAD&X-Amz-Credential=AKIA5TOQJU3JOA543BIW%2F20240904%2Fus-west-2%2Fs3%2Faws4_request&X-Amz-Date=20240904T143234Z&X-Amz-Expires=604800&X-Amz-Signature=f3fa4f3019f57a9a21a8354b89a38d6584ac08ef7f3dc5ade90250b8774ce0d8&X-Amz-SignedHeaders=host&x-id=GetObject",
              },
              id: "328838",
            },
          ],
        },
      },
      {
        id: "101",
        name: "Corporate Events",
        category: "Style",
        createdAt: "2024-07-12T15:22:14.017353+00:00",
        updatedAt: "2024-07-12T15:22:14.017353+00:00",
        coverDrop: {
          name: "1950s Abstract Retro Shapes Background",
          type: "DigitalDrops::Scene",
          cueDescription: null,
          cueLine: null,
          cuePageNumber: null,
          public: true,
          featuredRank: null,
          userStarsCount: 0,
          createdAt: "2024-05-07T15:20:31.920Z",
          updatedAt: "2024-05-21T13:11:27.915Z",
          id: "9025",
          attachments: [
            {
              name: "preview_still",
              recordType: "Scene",
              recordId: "9025",
              createdAt: "2024-05-07T15:21:19.007Z",
              blob: {
                key: "2fuogse2jvhkztqfnbmucjziifqg",
                filename: "1950s Retro Shapes Background-preview.webp",
                contentType: "image/webp",
                metadata: {
                  width: 320,
                  height: 180,
                  audio: false,
                  video: true,
                  video_codec: "WebP",
                  container_format: "piped webp sequence",
                  analyzed: true,
                  identified: true,
                },
                byteSize: "5476",
                checksum: "aqN0Dr6PnmjBFNDoQo26eQ==",
                serviceName: "s3",
                createdAt: "2024-05-07T15:21:18.941Z",
                id: "291293",
                signedUrl:
                  "https://bmd-accounts-production.s3.us-west-2.amazonaws.com/2fuogse2jvhkztqfnbmucjziifqg?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Content-Sha256=UNSIGNED-PAYLOAD&X-Amz-Credential=AKIA5TOQJU3JOA543BIW%2F20240904%2Fus-west-2%2Fs3%2Faws4_request&X-Amz-Date=20240904T143234Z&X-Amz-Expires=604800&X-Amz-Signature=76341917d2257bf8b92b36f6b030c796d897c58a30d047bd7d82612bb4235de4&X-Amz-SignedHeaders=host&x-id=GetObject",
              },
              id: "328834",
            },
            {
              name: "preview_video",
              recordType: "Scene",
              recordId: "9025",
              createdAt: "2024-05-07T15:21:25.618Z",
              blob: {
                key: "apoez5j3ps1j4oy3x3vn3knvk4ir",
                filename: "1950s Retro Shapes Background-preview.mp4",
                contentType: "video/mp4",
                metadata: {
                  width: 320,
                  height: 180,
                  duration: 5.005,
                  audio: false,
                  video: true,
                  video_codec: "H.264 / AVC / MPEG-4 AVC / MPEG-4 part 10",
                  container_format: "QuickTime / MOV",
                  analyzed: true,
                  identified: true,
                },
                byteSize: "15190",
                checksum: "MHt2yA8Ykhr85b5mI/lxAg==",
                serviceName: "s3",
                createdAt: "2024-05-07T15:21:25.550Z",
                id: "291297",
                signedUrl:
                  "https://bmd-accounts-production.s3.us-west-2.amazonaws.com/apoez5j3ps1j4oy3x3vn3knvk4ir?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Content-Sha256=UNSIGNED-PAYLOAD&X-Amz-Credential=AKIA5TOQJU3JOA543BIW%2F20240904%2Fus-west-2%2Fs3%2Faws4_request&X-Amz-Date=20240904T143234Z&X-Amz-Expires=604800&X-Amz-Signature=f3fa4f3019f57a9a21a8354b89a38d6584ac08ef7f3dc5ade90250b8774ce0d8&X-Amz-SignedHeaders=host&x-id=GetObject",
              },
              id: "328838",
            },
          ],
        },
      },
      {
        id: "20",
        name: "Dance",
        category: "Setting",
        createdAt: "2022-02-03T00:07:48.289125+00:00",
        updatedAt: "2024-07-10T19:26:54.531033+00:00",
        coverDrop: {
          name: "Bourbon Club. Interior Sign Off",
          type: "DigitalDrops::Scene",
          cueDescription: "",
          cueLine: "",
          cuePageNumber: "",
          public: true,
          featuredRank: null,
          userStarsCount: 0,
          createdAt: "2023-02-25T00:20:05.152Z",
          updatedAt: "2023-08-15T19:05:56.097Z",
          id: "7408",
          attachments: [
            {
              name: "preview_video",
              recordType: "Scene",
              recordId: "7408",
              createdAt: "2023-08-15T19:05:52.306Z",
              blob: {
                key: "jxfk5i7b2vjcyhre6ds8broc725u",
                filename: "ROA Bourbon Sign Off-preview.mp4",
                contentType: "video/mp4",
                metadata: {
                  width: 320,
                  height: 180,
                  duration: 5.005,
                  display_aspect_ratio: [16, 9],
                  audio: false,
                  video: true,
                  video_codec: "H.264 / AVC / MPEG-4 AVC / MPEG-4 part 10",
                  container_format: "QuickTime / MOV",
                  analyzed: true,
                  identified: true,
                },
                byteSize: "25199",
                checksum: "2c+VWeqIgJj6JudLDNSacw==",
                serviceName: "s3",
                createdAt: "2023-08-15T19:05:52.242Z",
                id: "249748",
                signedUrl:
                  "https://bmd-accounts-production.s3.us-west-2.amazonaws.com/jxfk5i7b2vjcyhre6ds8broc725u?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Content-Sha256=UNSIGNED-PAYLOAD&X-Amz-Credential=AKIA5TOQJU3JOA543BIW%2F20240904%2Fus-west-2%2Fs3%2Faws4_request&X-Amz-Date=20240904T143240Z&X-Amz-Expires=604800&X-Amz-Signature=eebd6e0d52ef8e36c244780163253e6ea9b4ed6252d696c968361b3c115b37e8&X-Amz-SignedHeaders=host&x-id=GetObject",
              },
              id: "285654",
            },
            {
              name: "preview_still",
              recordType: "Scene",
              recordId: "7408",
              createdAt: "2023-08-15T19:05:56.032Z",
              blob: {
                key: "x0p13tbklqlfj112d5pl7399kqcu",
                filename: "ROA Bourbon Sign Off-preview.webp",
                contentType: "image/webp",
                metadata: {
                  width: 320,
                  height: 180,
                  audio: false,
                  video: true,
                  video_codec: "WebP",
                  container_format: "piped webp sequence",
                  analyzed: true,
                  identified: true,
                },
                byteSize: "8364",
                checksum: "rUVb+TDiNWfLWQ4RfG4z/w==",
                serviceName: "s3",
                createdAt: "2023-08-15T19:05:55.968Z",
                id: "249749",
                signedUrl:
                  "https://bmd-accounts-production.s3.us-west-2.amazonaws.com/x0p13tbklqlfj112d5pl7399kqcu?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Content-Sha256=UNSIGNED-PAYLOAD&X-Amz-Credential=AKIA5TOQJU3JOA543BIW%2F20240904%2Fus-west-2%2Fs3%2Faws4_request&X-Amz-Date=20240904T143240Z&X-Amz-Expires=604800&X-Amz-Signature=37771d5f11006e84de86632834dd0f07af922f3dbdb76e0e25a300cb7ff5791e&X-Amz-SignedHeaders=host&x-id=GetObject",
              },
              id: "285655",
            },
          ],
        },
      },
      {
        id: "105",
        name: "Parties/Themed Events",
        category: "Style",
        createdAt: "2024-07-12T15:24:03.058322+00:00",
        updatedAt: "2024-09-04T14:22:44.841996+00:00",
        coverDrop: {
          name: "Fairytale Castle (Night, Exterior)",
          type: "DigitalDrops::Scene",
          cueDescription: "",
          cueLine: "",
          cuePageNumber: "",
          public: true,
          featuredRank: null,
          userStarsCount: 0,
          createdAt: "2021-12-09T22:08:46.288Z",
          updatedAt: "2022-12-19T00:56:34.810Z",
          id: "4398",
          attachments: [
            {
              name: "preview_still",
              recordType: "Scene",
              recordId: "4398",
              createdAt: "2022-07-21T03:50:36.738Z",
              blob: {
                key: "sxhiiai7wpz5s6rs1s3gh6xxx45j",
                filename: "964B-preview.webp",
                contentType: "image/webp",
                metadata: {
                  identified: true,
                  width: 320,
                  height: 180,
                  analyzed: true,
                },
                byteSize: "7322",
                checksum: "7qBaAKaFApWA9FUQjVepEQ==",
                serviceName: "s3",
                createdAt: "2022-07-21T03:50:36.736Z",
                id: "79319",
                signedUrl:
                  "https://bmd-accounts-production.s3.us-west-2.amazonaws.com/sxhiiai7wpz5s6rs1s3gh6xxx45j?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Content-Sha256=UNSIGNED-PAYLOAD&X-Amz-Credential=AKIA5TOQJU3JOA543BIW%2F20240904%2Fus-west-2%2Fs3%2Faws4_request&X-Amz-Date=20240904T145012Z&X-Amz-Expires=604800&X-Amz-Signature=977ed428844345b478c624d85b90e53d5ca88fac1d1ee7d7f33ea6c35403be35&X-Amz-SignedHeaders=host&x-id=GetObject",
              },
              id: "95528",
            },
            {
              name: "preview_video",
              recordType: "Scene",
              recordId: "4398",
              createdAt: "2022-12-19T00:56:34.809Z",
              blob: {
                key: "3y7wgey4pxwdfndlw9wqtqoy2fd0",
                filename: "964B-preview.mp4",
                contentType: "video/mp4",
                metadata: {
                  width: 320,
                  height: 180,
                  duration: 5.005,
                  audio: false,
                  video: true,
                  video_codec: "H.264 / AVC / MPEG-4 AVC / MPEG-4 part 10",
                  container_format: "QuickTime / MOV",
                  analyzed: true,
                  identified: true,
                },
                byteSize: "16449",
                checksum: "7qizHl3luVO/cYnhDHDUaw==",
                serviceName: "s3",
                createdAt: "2022-12-19T00:56:34.807Z",
                id: "151998",
                signedUrl:
                  "https://bmd-accounts-production.s3.us-west-2.amazonaws.com/3y7wgey4pxwdfndlw9wqtqoy2fd0?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Content-Sha256=UNSIGNED-PAYLOAD&X-Amz-Credential=AKIA5TOQJU3JOA543BIW%2F20240904%2Fus-west-2%2Fs3%2Faws4_request&X-Amz-Date=20240904T145012Z&X-Amz-Expires=604800&X-Amz-Signature=baaac410bd0dcd3b5d14b520d256f2a720bfb91d6d873c4303c1b87de7395b65&X-Amz-SignedHeaders=host&x-id=GetObject",
              },
              id: "178005",
            },
          ],
        },
      },
      {
        id: "98",
        name: "Weddings",
        category: "Style",
        createdAt: "2024-07-12T15:19:34.472927+00:00",
        updatedAt: "2024-07-12T15:19:34.472927+00:00",
        coverDrop: {
          name: "Castle Courtyard with Arches and Flower Garden, Night",
          type: "DigitalDrops::Scene",
          cueDescription: null,
          cueLine: null,
          cuePageNumber: null,
          public: true,
          featuredRank: null,
          userStarsCount: 0,
          createdAt: "2024-07-09T20:54:23.326Z",
          updatedAt: "2024-07-26T17:45:33.136Z",
          id: "9317",
          attachments: [
            {
              name: "preview_still",
              recordType: "Scene",
              recordId: "9317",
              createdAt: "2024-07-09T20:58:28.635Z",
              blob: {
                key: "f4wecub1ua0ig5sofbv0rpsn4ihq",
                filename: "Castle (Courtyard)-preview.webp",
                contentType: "image/webp",
                metadata: {
                  width: 320,
                  height: 180,
                  audio: false,
                  video: true,
                  video_codec: "WebP",
                  container_format: "piped webp sequence",
                  analyzed: true,
                  identified: true,
                },
                byteSize: "9372",
                checksum: "lihArQgwYmGwBtnttGs0cw==",
                serviceName: "s3",
                createdAt: "2024-07-09T20:58:28.567Z",
                id: "301797",
                signedUrl:
                  "https://bmd-accounts-production.s3.us-west-2.amazonaws.com/f4wecub1ua0ig5sofbv0rpsn4ihq?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Content-Sha256=UNSIGNED-PAYLOAD&X-Amz-Credential=AKIA5TOQJU3JOA543BIW%2F20240904%2Fus-west-2%2Fs3%2Faws4_request&X-Amz-Date=20240904T145013Z&X-Amz-Expires=604800&X-Amz-Signature=125f14eb18a9559944240b5fe383e9309774b02c2b2cbf5316cfd9061e1381b3&X-Amz-SignedHeaders=host&x-id=GetObject",
              },
              id: "339584",
            },
            {
              name: "preview_video",
              recordType: "Scene",
              recordId: "9317",
              createdAt: "2024-07-09T20:58:29.114Z",
              blob: {
                key: "8jl8kx9ilqyx589u3u10buj11wqj",
                filename: "Castle (Courtyard)-preview.mp4",
                contentType: "video/mp4",
                metadata: {
                  width: 320,
                  height: 180,
                  duration: 5.005,
                  audio: false,
                  video: true,
                  video_codec: "H.264 / AVC / MPEG-4 AVC / MPEG-4 part 10",
                  container_format: "QuickTime / MOV",
                  analyzed: true,
                  identified: true,
                },
                byteSize: "19694",
                checksum: "4FPzd0W6oTuI5/H6eFPbvg==",
                serviceName: "s3",
                createdAt: "2024-07-09T20:58:29.047Z",
                id: "301798",
                signedUrl:
                  "https://bmd-accounts-production.s3.us-west-2.amazonaws.com/8jl8kx9ilqyx589u3u10buj11wqj?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Content-Sha256=UNSIGNED-PAYLOAD&X-Amz-Credential=AKIA5TOQJU3JOA543BIW%2F20240904%2Fus-west-2%2Fs3%2Faws4_request&X-Amz-Date=20240904T145013Z&X-Amz-Expires=604800&X-Amz-Signature=9057ea27eb76f398d0cf039ba66f6f9716530f18572551835d6f099876b2aff4&X-Amz-SignedHeaders=host&x-id=GetObject",
              },
              id: "339585",
            },
          ],
        },
      },
      {
        id: "102",
        name: "Theatre",
        category: "Style",
        createdAt: "2024-07-12T15:22:50.728266+00:00",
        updatedAt: "2024-07-12T15:22:50.728266+00:00",
        coverDrop: {
          name: "1920s Night Club with Stage",
          type: "DigitalDrops::Scene",
          cueDescription: null,
          cueLine: null,
          cuePageNumber: null,
          public: true,
          featuredRank: null,
          userStarsCount: 0,
          createdAt: "2024-05-28T17:47:22.275Z",
          updatedAt: "2024-06-25T15:21:28.919Z",
          id: "9079",
          attachments: [
            {
              name: "preview_video",
              recordType: "Scene",
              recordId: "9079",
              createdAt: "2024-05-28T17:54:41.646Z",
              blob: {
                key: "jzdtrowym7urav85c74ibgm9da2j",
                filename: "06 Night Club Drop 30S-preview.mp4",
                contentType: "video/mp4",
                metadata: {
                  width: 320,
                  height: 180,
                  duration: 5.005,
                  audio: false,
                  video: true,
                  video_codec: "H.264 / AVC / MPEG-4 AVC / MPEG-4 part 10",
                  container_format: "QuickTime / MOV",
                  analyzed: true,
                  identified: true,
                },
                byteSize: "13585",
                checksum: "ZXob9Uv/GcP0baXxeTADgQ==",
                serviceName: "s3",
                createdAt: "2024-05-28T17:54:41.580Z",
                id: "292737",
                signedUrl:
                  "https://bmd-accounts-production.s3.us-west-2.amazonaws.com/jzdtrowym7urav85c74ibgm9da2j?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Content-Sha256=UNSIGNED-PAYLOAD&X-Amz-Credential=AKIA5TOQJU3JOA543BIW%2F20240904%2Fus-west-2%2Fs3%2Faws4_request&X-Amz-Date=20240904T145012Z&X-Amz-Expires=604800&X-Amz-Signature=e9ef90a67e87e5fd72887d3836dc3810bf741c8e555e7b3b4ee3bd43574f7d35&X-Amz-SignedHeaders=host&x-id=GetObject",
              },
              id: "330276",
            },
            {
              name: "preview_still",
              recordType: "Scene",
              recordId: "9079",
              createdAt: "2024-05-28T17:54:44.336Z",
              blob: {
                key: "hygfwdyzw1s227nyajlnuvvlr0tf",
                filename: "06 Night Club Drop 30S-preview.webp",
                contentType: "image/webp",
                metadata: {
                  width: 320,
                  height: 180,
                  audio: false,
                  video: true,
                  video_codec: "WebP",
                  container_format: "piped webp sequence",
                  analyzed: true,
                  identified: true,
                },
                byteSize: "7958",
                checksum: "bWtCiQ5b4hAqLvcLK8OZAg==",
                serviceName: "s3",
                createdAt: "2024-05-28T17:54:44.274Z",
                id: "292742",
                signedUrl:
                  "https://bmd-accounts-production.s3.us-west-2.amazonaws.com/hygfwdyzw1s227nyajlnuvvlr0tf?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Content-Sha256=UNSIGNED-PAYLOAD&X-Amz-Credential=AKIA5TOQJU3JOA543BIW%2F20240904%2Fus-west-2%2Fs3%2Faws4_request&X-Amz-Date=20240904T145012Z&X-Amz-Expires=604800&X-Amz-Signature=e7cbb8057e1b3c35b1b419755895e59ec1ce76ff208345ace871a401e8b31c87&X-Amz-SignedHeaders=host&x-id=GetObject",
              },
              id: "330281",
            },
          ],
        },
      },
    ];
  }
}
