import { SortField } from "@with-nx/simple-ui/organisms";
import { useEffect, useState } from "react";

interface UseInfiniteListProps {
  page: number;
  _page: (page: number) => void;
  search: string;
  _inputLoading: (loading: boolean) => void;
  sortBy?: SortField;
  fetchDataHook?: any;
  category?: string;
}

const DEFAULT_PAGE_SIZE = 20;

export const useInfiniteList = ({
  page,
  _page,
  search,
  _inputLoading,
  sortBy,
  fetchDataHook,
  category,
}: UseInfiniteListProps) => {
  const [currentSearch, _currentSearch] = useState<string | undefined>();
  const [currentSortBy, _currentSortBy] = useState<SortField | undefined>();
  const [loadingMore, _loadingMore] = useState(false);
  const [items, _items] = useState<any[]>([]);

  const { data, mutate, loading, pages } = fetchDataHook({
    page,
    per: DEFAULT_PAGE_SIZE,
    search,
    sortBy,
    category,
  });

  const hasNextPage = page !== pages;

  const onLoadMore = () => {
    if (hasNextPage && !loadingMore) {
      _page(page + 1);
    }
  };

  useEffect(() => {
    _items((previous) => [...previous, ...data]);
    _inputLoading(false);
    _loadingMore(false);
  }, [data]);

  useEffect(() => {
    const firstSearch = search?.trim() && page == 1;
    const searchChanged = search !== currentSearch;
    const sortChanged =
      sortBy && JSON.stringify(sortBy) !== JSON.stringify(currentSortBy);

    _loadingMore(true);

    if (firstSearch || searchChanged) {
      _items([]);
      _page(1);
      _currentSearch(search);
      _inputLoading(true);

      mutate({ page: 1, search, per: DEFAULT_PAGE_SIZE, sortBy });
      return;
    }

    if (sortChanged) {
      _items([]);
      _page(1);

      mutate({ page: 1, search, per: DEFAULT_PAGE_SIZE, sortBy });
      _currentSortBy(sortBy);
      return;
    }

    mutate({ page, search, per: DEFAULT_PAGE_SIZE, sortBy });
  }, [page, search, sortBy]);

  return {
    items,
    loading,
    loadingMore,
    onLoadMore,
    hasNextPage,
  };
};
